<template>
  <v-simple-table style="background:transparent">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('Qtd') }}
          </th>
          <th class="text-left">
            {{ $t('Price') }}
          </th>
          <th>
            {{ $t('Actions') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in orderedItems"
          :key="item.id"
        >
          <td>{{ $t('From') }} {{ item.qtd }}</td>
          <td>
            {{ convertPriceValue(item.new_price) | VMask(priceMask) }} 
            <span v-if="item.type == 'percent'" style="color:grey">({{ item.value }}% {{ $t('discount') }})</span>
          </td>
          <td width="160">
            <v-icon
              class="mr-2"
              @click="deletePackage(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import i18n from '@/plugins/i18n.js'

export default {
  name: 'PackageInfo',

  props: {
    packages: Array,
    price: Number
  },

  data() {
    return {
      priceMask: createNumberMask({
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
        decimalSymbol: i18n.t('decimalSymbol'),
        prefix: i18n.t('currencyPrefix')
      })
    }

  },

  computed: {
    orderedItems() {
      let packages = this.packages

      packages.sort((a,b) => a.qtd - b.qtd)

      packages.map((item => {
        item.new_price = item.value

        if (item.type == 'percent') {
          item.new_price = this.price > 0 && item.value > 0 ? this.price - (this.price / 100 * item.value) : 0
        }

      }))

      return packages
    }
  },

  methods: {
    convertPriceValue(price) {
      if (!price) {
        return price;
      }

      return price
        .toString()
        .replace('.', i18n.t("decimalSymbol"))
    },

    deletePackage(item) {
      this.$emit('delete', item)
    }
  }
}
</script>
