<template>
  <BasicCrud
    ref="crud"
    :fields="fields"
    :title="$t('Packages')"
    path="package"
    :priceColumns="['item.value', 'item.qtd']"
    custom-save-event="save"
    :showNewButton="isMaster()"
    @save="save"
  >
    <template v-slot:beforesearch>
      <v-switch
        style="margin-top:22px"
        v-model="onlyActive"
        :label="$t('Only active products')"
        @click="reloadData"
      ></v-switch>
    </template>

    <template v-slot:table>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="loading"
        :items-per-page="20"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100]}"
        :server-items-length="itemsLength"
        show-expand
      >

        <template v-for="(pc, i) in priceColumns" v-slot:[pc]="{ item }">
          <span :key="i" v-if="item[pc.replace('item.', '')]">{{ convertPriceValue(item[pc.replace('item.', '')]) | VMask(priceMask) }}</span>
        </template>

        <template v-slot:item.packages="{ item }">
          {{ item.packages.length }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="padding: 20px">
            <PackageInfo
              :packages="item.packages"
              :price="item.price"
              @delete="deletePackage"
            ></PackageInfo>
          </td>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            @click="addPackage(item)"
            v-if="isMaster()"
          >
             mdi-package-variant-closed-plus
          </v-icon>
        </template>
      </v-data-table>
    </template>
  </BasicCrud>
</template>

<script>

import Vue from 'vue'
import BasicCrud from '@/components/BasicCrud'
import PackageInfo from '@/components/PackageInfo'
import { mapState } from 'vuex'
import i18n from '@/plugins/i18n.js'
import api from '@/services/api'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export default {
  name: "Package",

  components: {
    BasicCrud, PackageInfo
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  mounted() {
    this.reloadData()
    this.loadProducts()
  },

  computed: {
    ...mapState({
      userData: state => state.app.userData,
      fields: state => state.pack.fields,
      headers: state => state.pack.headers
    }),
  },

  data() {
    return {
      priceColumns: ['item.price'],
      items: [],
      options: {},
      itemsLength: 20,
      loading: false,
      priceMask: createNumberMask({
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
        decimalSymbol: i18n.t('decimalSymbol'),
        prefix: i18n.t('currencyPrefix')
      }),
      onlyActive: true
    }
  },

  methods: {
    reloadData() {
      this.loading = true

      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.page = this.options.page
      options.search = this.search

      if (this.onlyActive) {
        options["filters[active]"] = 1
      }

      api
        .find('package', options)
        .then((response) => {
          this.items = response.data.results
          this.itemsLength = response.data.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    convertPriceValue(price) {
      if (!price) {
        return price;
      }

      return price
        .toString()
        .replace('.', i18n.t("decimalSymbol"))
    },

    loadProducts() {
      api.find('product', {limit: 10000, order: 'title', "filters[active]": 1})
        .then((response) => this.fields.find(field => field.name == 'product').items = response.data.results)
    },

    save(data) {
      api.create('package', data)
        .then(() => {
          this.reloadData()
          this.$refs.crud.closeModal()
        })
        .catch(() => {
          this.loading = false
          this.$refs.crud.closeModal()
        })
    },

    deletePackage(item) {
      this.loading = true
      api.delete('package', item.id)
        .then(() => {
          this.reloadData()
        })
        .catch(() => this.loading = false)

    },

    addPackage(item) {
      let field = this.fields.find(field => field.name == 'product')

      this.$refs.crud.openNewModal()

      Vue.set(field, 'readonly', true)
      Vue.set(field, 'value', item.id)
    },

    isMaster() {
      return this.userData.roles.find(role => role == 'ROLE_MASTER') ? true : false
    }
  }
}

</script>
