var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasicCrud',{ref:"crud",attrs:{"fields":_vm.fields,"title":_vm.$t('Packages'),"path":"package","priceColumns":['item.value', 'item.qtd'],"custom-save-event":"save","showNewButton":_vm.isMaster()},on:{"save":_vm.save},scopedSlots:_vm._u([{key:"beforesearch",fn:function(){return [_c('v-switch',{staticStyle:{"margin-top":"22px"},attrs:{"label":_vm.$t('Only active products')},on:{"click":_vm.reloadData},model:{value:(_vm.onlyActive),callback:function ($$v) {_vm.onlyActive=$$v},expression:"onlyActive"}})]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":20,"options":_vm.options,"footer-props":{'items-per-page-options': [10, 20, 30, 40, 50, 100]},"server-items-length":_vm.itemsLength,"show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.priceColumns),function(pc,i){return {key:pc,fn:function(ref){
var item = ref.item;
return [(item[pc.replace('item.', '')])?_c('span',{key:i},[_vm._v(_vm._s(_vm._f("VMask")(_vm.convertPriceValue(item[pc.replace('item.', '')]),_vm.priceMask)))]):_vm._e()]}}}),{key:"item.packages",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.packages.length)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"20px"},attrs:{"colspan":headers.length}},[_c('PackageInfo',{attrs:{"packages":item.packages,"price":item.price},on:{"delete":_vm.deletePackage}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isMaster())?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.addPackage(item)}}},[_vm._v(" mdi-package-variant-closed-plus ")]):_vm._e()]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }